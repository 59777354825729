@import url("./global.scss");
@import url("./layout.scss");
@import url("./login.scss");
@import url("./profile.scss");
@import url("./clientSidebar.scss");
@import url("./workersList.scss");
@import url("./userdetails.scss");
@import url("./profile.scss");
@import url("./chatScreen.scss");
@import url("./jobsDashboard.scss");
@import url("./homeScreen.scss");
@import url("./payment.scss");
@import url("./subscription.scss");
@import url("./stripe.scss");
@import url("./subscriptionModal.scss");
@import url("./notification.scss");
@import url("./searchMain.scss");
@import url("./profileCard.scss");
@import url("./viewDetails.scss");
@import url("./hideProfessional.scss");
@import url("./supportScreen.scss");

.img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
.ant-upload-list-item-error {
  color: #50a14f !important;
  border-color: #50a14f !important;
}
.ant-upload-list-item-name {
  color: #50a14f !important;
}
.ant-upload-list-item-actions .anticon {
  color: #50a14f !important;
}
.ant-upload-list-item-actions .anticon:hover {
  color: #50a14f !important;
}

* {
  margin: 0;
  padding: 0;
}

body {
  font-family: Arial, Helvetica, sans-serif;
  background-color: #ecd7b5;
}

.main {
  width: 90%;
  margin: auto;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  @media screen and (max-width: 600px) {
    width: 95%;
    margin: auto;
    min-height: 100vh;
    display: flex;
  }
  @media screen and (max-width: 450px) {
    width: 98%;
  }
}
.outlined-color {
  color: #198754;
}
.close-outlined {
  color: #ff0000;
}
.main_inner {
  width: 100%;
  min-height: 100vh;
  margin-top: 10px;
  display: flex;
  justify-content: center;
}
$large: 1200px;
$medium: 900px;
$small: 600px;
$mobile: 450px;
$primary-color: #f48c00;
$primary-light: #60331d;
$secondary-color: #f8de7e;
$text-black: black;
$text-white: white;
$purple: #97659350;
$blue: #172b4d;
$bg-chat: #757575;
$bg-sider: #262626;
$text-grey: #8f9198;
$bg-grey: #dadbda;
$secondary-light: #ecd7b5;
$brown: #b86d53;
$yellow: #ffcd2e;
$brown-light: #f48c00;
// $secondary-dark: rgb(245, 178, 91);
$secondary-dark: #f48c00;
$success-color: #50a14f;

@import "./global.scss";

.main-detail {
  width: 90%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: whitesmoke;
  .inner {
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 50px;
  }

  .inner-detail {
    margin-top: 20px;
    width: 90%;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 20px;
  }
}

@import url("./global.scss");

.card_container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
  flex-wrap: wrap;
  margin-top: 20px;
  margin-bottom: 20px;
}
.card-container-main{
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  align-items: center;
  justify-content: center;
}

.main_card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  .card_inner {
    display: flex;
    gap: 10px;
    align-items: center;
  }
  .title {
    font-size: 1rem;
    font-weight: 600;
  }
}
.button_professional {
  border-radius: 30px;
  height: 40px;
}

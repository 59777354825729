@import "./global.scss";

.main_chat {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 20px;

  .img_div {
    border: 1px solid #dddddd;
    height: 50px;
    width: 50px;
    border-radius: 50px;
  }
  .chat_content {
    width: 70%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    border: 1px solid #dddddd;
    background: $text-white;
    justify-content: space-between;
    @media screen and (max-width: $medium) {
      display: none;
    }
  }
  .message_div {
    background: $secondary-color;
    border-radius: 10px;
    gap: 5px;
    padding: 10px;
    margin: 10px;
    margin-left: 40%;
  }
  .message_receive {
    height: auto;
    background: #dddddd;
    border-radius: 10px;
    display: flex;
    gap: 5px;
    padding: 10px;
    margin: 10px;
    margin-right: 35%;
    margin-left: 5%;
  }
  .chat_welcome {
    display: flex;
    height: 100vh;
    justify-content: center;
    border: 1px solid #dddddd;
    align-items: center;
    flex-direction: column;
    background-color: $text-white;
    .title {
      font-size: 28px;
      @media screen and (max-width: $medium) {
        font-size: 22px;
      }
    }
    .paragraph {
      font-size: 22px;
      @media screen and (max-width: $medium) {
        font-size: 16px;
      }
    }
  }

  .show_content {
    width: 70%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: $text-white;
    border: 1px solid #dddddd;
    @media screen and (max-width: $medium) {
      display: block;
      width: 70%;
      height: 100vh;
      overflow: auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background: $text-white;
      width: 90%;
    }
  }
  .Title_chat_header {
    @media screen and (max-width: $mobile) {
      font-size: 16px;
    }
  }
  .heading {
    height: 60px;
    display: flex;
    align-items: center;
    gap: 20px;
    background: $secondary-color;
    padding: 10px;
    justify-content: space-between;
    .first {
      display: flex;
      gap: 10px;
      align-items: center;
    }
  }
}
.chat_sider {
  width: 30%;
  color: $text-black;
  background: $text-white;
  height: 100vh;
  // border: 2px solid green;
  border-right: 1px solid #dddddd;
  @media screen and (max-width: $medium) {
    width: 90%;
    border-radius: 10px;
    height: 100vh;
  }
  @media screen and (max-width: $mobile) {
    width: 100%;
  }
}
.combine_sider {
  height: 100vh;
  // border:2px solid green;
  background: $text-white;
}
.combine_chat_screen {
  height: 100vh;
  // border:2px solid green;
  background: $text-white;
}
.main_sider {
  height: 86vh;
  overflow: auto;
  background: $text-white;
  @media screen and (max-width: $large) {
    // height: 86vh;
    background: $text-white;
  }
}
.heading_sider {
  height: 52px;
  padding: 10px;
  text-align: center;
  background: $secondary-color;
  display: flex;
  align-items: center;
  justify-content: center;
  .text_conversation {
    @media screen and (max-width: $mobile) {
      font-size: 16px;
    }
  }
}
.contacts {
  padding: 10px;
  text-align: justify;
  align-items: center;
  display: flex;
  gap: 5px;
  border-bottom: 1px solid #dddddd;
}
.Title {
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  &:hover {
    color: $blue;
    text-decoration: underline;
  }
}
.text {
  font-weight: 500;
  color: $text-grey;
  @media screen and (max-width: $mobile) {
    font-size: 12px;
  }
}
.fot-text {
  color: #f48c00;
  font-weight: 500;
  font-size: 1rem;
}
.hide_sider {
  width: 30%;
  border-right: 1px solid #dddddd;
  color: $text-black;
  height: 100vh;
  background: $text-white;
  @media screen and (max-width: $medium) {
    display: none;
  }
}
.desktop-text {
  @media screen and (max-width: $mobile) {
    display: none !important;
  }
}
.mobile-text {
  display: none !important;
  @media screen and (max-width: $mobile) {
    display: inline !important;
  }
}

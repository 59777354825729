@import "./global.scss";

.main_listing_filter {
  display: flex;
  width: 100%;
  position: relative;
  @media screen and (max-width: $medium) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}
.main_listing_1 {
  width: 80%;
  @media screen and (max-width: $medium) {
    width: 80%;
  }
  @media screen and (max-width: $small) {
    width: 95%;
  }
}
.scroll_text {
  width: 100%;
  text-align: center;
  font-size: 18px;
  margin-bottom: 10px;
}
.main_listing_inner {
  margin: 20px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background-color: $text-white;
}

.main_workers {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: 50px;
}
.workers_box {
  width: 200px;
  height: 300px;
  margin: 10px;
  padding: 10px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background: linear-gradient($secondary-color, white);
  @media screen and (max-width: $mobile) {
    width: 150px;
  }
  .workers_inner_box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    .title_div {
      display: flex;
      align-items: center;
    }
    .Title {
      text-transform: capitalize;
      &:hover {
        color: $blue;
        text-decoration: underline;
      }
    }
    .text {
      color: $text-grey;
      font-size: 1rem;
      text-align: center;
    }
    .col_design {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-align: justify;
      p {
        padding: 5px;
      }
      b {
        padding: 5px;
      }
    }
    .div_btn {
      display: flex;
      justify-content: flex-end;
    }
  }
}

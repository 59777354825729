@import "./global.scss";
.main-client {
  position: relative;
  width: 20%;
  // border: 1px solid black;
  display: flex;
  justify-content: center;
  margin: 0px;
  @media screen and (max-width: $medium) {
    width: 70%;
    position: static;
    height: auto;
  }
  @media screen and (max-width: $mobile) {
    width: 80%;
  }
  .main_client_sider {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 200px;
    margin-top: 20px;
    padding: 20px;
    border-radius: 10px;
    height: 65vh;
    position: fixed;
    z-index: 1;
    background: linear-gradient(to bottom, $secondary-color 10%, white 90%);
    @media screen and (max-width: $large) {
      width: 150px;
    }
    @media screen and (max-width: $medium) {
      position: static;
      height: auto;
      width: 90%;
    }
    .client_sider_inner {
      .search_input {
        width: 100%;
      }
      .select_input {
        width: 100%;
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }
  }
}

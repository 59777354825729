@import "./global.scss";

.search_main {
  margin-top: 10px;
  width: 100%;
  .search_input {
    width: 100%;
    display: flex;
    @media screen and (max-width: $medium) {
      display: flex;
      flex-direction: column;
    }
    .selector_jobTypes {
      width: 75%;
      .job-type {
        height: 40px;
      }
    }
    .button_search {
      width: 25%;
      .btn_find {
        height: 40px;
        @media screen and (max-width: $medium) {
          margin-top: 10px;
        }
      }
    }
  }
}

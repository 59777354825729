@import "./global.scss";

.main_profile {
  width: 100%;
  padding: 30px;
  margin: 20px;
  border-radius: 5px;
  // background: #c9d8bfd9;
  background: $text-white;

  .profile_inner {
    width: 100%;
    .upload_img {
      display: flex;
      justify-content: center;
      .profile_img {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        padding: 10px;
      }
      .camera_icon {
        border-radius: 50%;
        padding: 6px;
        background-color: $primary-color;
        font-size: 16px;
        color: white;
        position: absolute;
        bottom: 10px;
        right: 20px;
      }
    }
    .btn_div {
      display: flex;
      justify-content: flex-end;
      margin-right: 20px;
      @media screen and (max-width: $small) {
        margin-right: 10px;
      }
    }
    .profile_inputs {
      width: 100% !important;
      margin-top: 10px;
      padding: 10px;
    }
    .selector_inputs {
      width: 100%;
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .form-control {
      height: 45px !important;
      width: 100% !important;
    }
    .react-tel-input {
      margin-top: 10px !important;
      width: 100% !important;
    }
    .button_div {
      display: flex;
      justify-content: center;
    }
    label {
      font-weight: bold;
      color: $text-black;
    }
  }
}
.ant-btn-primary {
  background: $primary-color;

  &:hover {
    background: $secondary-dark !important ;
    // opacity: 0.8 !important;
    // color: $text-black !important;
  }
}


@import "./global.scss";
.jobs_main {
  padding: 10px;
  margin: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .jobs_inner {
    display: flex;
    padding: 20px;
    width: 90%;
    justify-content: space-evenly;
    border-radius: 10px;
    flex-wrap: wrap;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: $text-white;
    gap: 20px;
    @media screen and (max-width: $mobile) {
      width: 80%;
    }
    @media screen and (max-width: 300px) {
      width: 75%;
    }
    .jobs_box {
      width: 180px;
      height: 180px;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      background: $secondary-dark;
      border-radius: 10px;
      padding: 10px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      @media screen and (max-width: $mobile) {
        width: 150px;
        height: 150px;
      }
      .title {
        font-size: 20px;
        font-weight: bold;
      }
    }
  }
  .tags_div {
    padding: 10px;
    margin-left: 25px;
    margin-top: 10px;
    display: flex;
    align-self: flex-start;
    @media (max-width: $mobile) {
      margin-left: 10px;
    }
    .btn_status {
      background: $primary-color;
      color: $text-white;
      &:hover {
        background: $text-white;
        color: $text-black;
      }
    }
  }
  .jobs_status {
    width: 90%;
    padding: 30px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: $text-white;
    border-radius: 10px;
    margin: 10px;
    @media screen and (max-width: $mobile) {
      width: 80%;
      padding: 10px;
    }
    .jobs_box_inner_1 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      @media screen and (max-width: $medium) {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 20px;
        width: auto;
        gap: 10px;
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.08),
          0 2px 4px 0 rgba(0, 0, 0, 0.12);
        margin: 20px;
        border-radius: 10px;

      }
    }

    .title_2 {
      font-size: 20px;
      font-weight: 500;
    }
    .item_1 {
      display: flex;
      gap: 20px;
      align-items: center;
      width: 30%;
      @media screen and (max-width: $medium) {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }
      @media screen and (max-width: $mobile) {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
      }
      .profile_div {
        width: 15%;
        @media screen and (max-width: $medium) {
          width: auto;
        }
      }
      .title_Description_div {
        width: 85%;
        @media screen and (max-width: $medium) {
          width: auto;
        }
      }
    }
    .item_2 {
      display: flex;
      width: 30%;
      justify-content: center;
      gap: 10px;
      align-items: center;
      @media screen and (max-width: $medium) {
        width: 100%;
      }
      @media screen and (max-width: $mobile) {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
      }
    }
    .item_3 {
      width: 30%;
      display: flex;
      justify-content: flex-end;
      gap: 10px;
      @media screen and (max-width: $medium) {
        width: 100%;
        display: flex;
        justify-content: center;
      }
      @media screen and (max-width: $mobile) {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
      }
    }
  }
}
.ant-tabs-nav {
  display: flex;
  justify-content: center;
}

/* Responsive styles for smaller screens */
@media (max-width: $mobile) {
  .ant-tabs-nav {
    width: 200px !important;
  }
}

@import "./global.scss";

.main_home {
  width: 100%;
  .main_home_img {
    width: 100%;
  }
  .main_img_div {
    width: 100%;
    height: 70vh;
    background-image: url("../components/asset/HomeBg.jpg");
    background-position: center;
    background-size: cover;
  }
  .main_home_inner {
    background: #f9f8f6;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .hiring {
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    .main-hiring {
      width: 100%;
      display: flex;
      // border: 1px solid red;
      gap: 20px;
      margin-top: 40px;
      padding: 20px;
      flex-direction: column;
      .hiring_three {
        display: flex;
        justify-content: space-between;
        @media screen and (max-width: $medium) {
          display: flex;
          gap: 20px;
          flex-direction: column;
        }
        .hiring_inner {
          width: 30%;
          @media screen and (max-width: $medium) {
            width: 80%;
          }
          @media screen and (max-width: $mobile) {
            width: 100%;
          }
          .first_hiring {
            display: flex;
            gap: 10px;

            .map_2 {
              display: flex;
              gap: 10px;
              flex-direction: column;
            }
          }
        }
      }
    }
  }
  .Title{
    font-size: 20px;
  }

  .Title1{
    font-size: 14px;
    color: #1677ff;
    text-decoration: underline;
    font-weight: 400;
  }

  .main_text {
    width: 100%;
    height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(60, 60, 60, 0.5);
    .home_text {
      padding: 10px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .title {
        font-size: 1.5rem;
        color: $text-white;
        @media screen and (max-width: $small) {
          font-size: 0.8rem;
          font-weight: 700;
        }
      }
      .text_Home {
        font-size: 1rem;
        color: $text-white;
        @media screen and (max-width: $small) {
          font-size: 12px;
        }
      }
      .btn_home {
        background: $primary-color;
        color: $text-white;
        margin: 10px;
        width: 120px;
        &:hover {
          background: $primary-color;
          opacity: 0.8;
          color: $text-black;
          margin: 10px;
        }
      }
    }
  }

  .title1 {
    font-size: 1.6rem;
    font-weight: 600;
    @media screen and (max-width: $small) {
      font-size: 1.5rem;
    }
    @media screen and (max-width: $mobile) {
      font-size: 1.5rem;
    }
  }
  .title2 {
    font-size: 1.5rem;
    margin: 20px;
    text-align: center;
  }
  .title3 {
    font-size: 1.4rem;
    font-weight: 600;
  }
  .title4 {
    font-size: 1.5rem;
    text-align: start;
  }
  .text3 {
    color: $text-grey;
    font-size: 1rem;
  }
  .works {
    width: 95%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    background-color: $text-white;
    margin: 10px;
    padding: 10px;
    @media screen and (max-width: $medium) {
      width: 90%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
  }
  .works_inner {
    width: 45%;
    margin: 10px;
    display: flex;
    gap: 20px;
    .icon_div {
      color: $secondary-dark;
    }

    @media screen and (max-width: $small) {
      width: 90%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .categories {
    background: $text-white;
    // border: 1px solid red;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .main-categories {
      width: 90%;
      // border: 1px solid red;
    }
  }

  .main_listing {
    margin-top: 50px;
    background-color: $text-white;
    display: flex;
    justify-content: center;
    width: 80%;
    padding: 20px;
    margin-left: 90px;
    border-radius: 20px;
    @media screen and (max-width: $small) {
      width: 90%;
      background-color: $secondary-dark;
      padding: 10px;
      margin-left: 10px;
    }
    @media screen and (max-width: $mobile) {
      padding: 0px;
    }
    .listing_inner {
      width: 100%;
      background-color: $secondary-dark;
      border-radius: 10px;
    }
    .title3 {
      display: flex;
      justify-content: center;
      width: 100%;
      padding: 10px;
      margin-top: 10px;
      @media screen and (max-width: $small) {
        font-size: 16px;
      }
    }
  }
  .main_carousel {
    padding: 20px;
    border-radius: 20px;
    width: 100%;
    @media screen and (max-width: $small) {
      width: 100%;
      padding: 10px;
    }
  }
}
.howItWorksBtn {
  @media screen and (max-width: $small) {
    display: none;
  }
}
.postAJobBtn {
  @media screen and (max-width: $small) {
    display: none;
  }
}
.footer_text {
  @media screen and (max-width: $small) {
    font-size: 12px;
  }
}

@import "./global.scss";

.subscription_main {
  width: 100%;
  .subscription_inner {
    margin: 10px;
  }
  .subscription_div_1 {
    text-align: center;
    width: 100%;
  }
  .title_subscription {
    font-size: 18px;
    font-weight: bold;
  }

  .text_subscription_1 {
    font-size: 20px;
    @media screen and (max-width: $mobile) {
      font-size: 16px;
    }
  }
  .text_subscription {
    font-size: 16px;
  }
  .first_headings {
    display: flex;
    gap: 10px;
    align-items: center;
    .icon_tick {
      @media screen and (max-width: $mobile) {
        display: none;
      }
    }
  }

  .headings_div {
    padding: 10px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .subscription_card_div {
    margin: 20px;
    display: flex;
    justify-content: center;
    gap: 30px;
    @media screen and (max-width: $medium) {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
  }
  .card_design_subscription {
    width: 40%;
    @media screen and (max-width: $large) {
      width: 50%;
    }
    @media screen and (max-width: $medium) {
      width: 65%;
    }
    @media screen and (max-width: $small) {
      width: 100%;
    }
  }
  .title_subscription_1 {
    font-size: 22px;
    font-weight: bold;
    @media screen and (max-width: $mobile) {
      font-size: 16px;
    }
  }
  .btn_subscription {
    margin-top: 10px;
  }
}
.subscription_modal_btn_div {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  gap: 20px;
}

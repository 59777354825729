@import "./global.scss";

header {
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.08), 0 2px 4px 0 rgba(0, 0, 0, 0.12);
  background-color: $text-white;
  height: 70px;
  line-height: 70px;
  padding-top: 10px;
  width: 100%;
  position: fixed;
  z-index: 999;
  .logo {
    font-size: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 12px;
    margin-right: 12px;
  }
  .navbar_main {
    display: flex;
    align-items: center;
    gap: 10px;
    .drawer-hide {
      margin-bottom: 10px;
      @media screen and (max-width: $small) {
        display: none;
      }
    }
    .selector_inputs {
      @media screen and (max-width: $small) {
      }
    }
  }


  // $primary-color: #5c4033;
  // $primary-light: #60331d;
  .navbar_drawer {
    .drawer-btn {
      width: 30px;
      display: grid;
      justify-content: center;
      align-items: center;
      margin-bottom: 10px;
      background: $primary-color;
      color: $text-white;
    }
    .drawer-btn:hover {
      color: $text-white !important;
    }

    @media screen and (max-width: $small) {
      display: block;
    }
    @media screen and (min-width: $small) {
      display: none;
    }
    @media screen and (max-width: $medium) {
      margin-top: 5px;
    }
  }
}

footer {
  height: 70px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  // background: $secondary-color;
  background: $text-white;
  width: 100%;
}
.main_footer {
  width: 100%;
  margin-top: 100px;
  // background-color: $text-white;
  background-color: #1c1d1e;
  padding-top: 20px;
  .col_design {
    text-align: center;
  }
  .text {
    font-size: 1rem;
  }
  .items {
    font-size: 1rem;
    // color: #b9770e;
    color: white;
  }
  .items:hover {
    color: #f48c00;
  }
  .icons {
    font-size: 22px;
    // background-color: #b9770e;
    background-color: #f48c00;
    padding: 5px;
    border-radius: 50%;
    margin: 10px;
    color: aliceblue;
  }
}

.main_payments {
  width: 100%;
  text-align: center;
  .title_btn {
    display: flex;
    justify-content: space-between;
    margin: 20px;
  }
  .filter_payment {
    display: flex;
    justify-content: center;
    margin: 20px;
  }
  .inner_1 {
    width: 50%;
  }
  .table_div {
    margin: 10px;
  }
}

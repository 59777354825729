@import "./global.scss";

.notification_main {
  max-height: 400px;
  overflow: auto;
}
.ant-popover {
  width: 30% !important;
  @media screen and (max-width: $small) {
    width: 50% !important;
  }
  @media screen and (max-width: $mobile) {
    width: 70% !important;
  }
}
::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  background-color: darkgrey;
}

::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}


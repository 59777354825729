@import "./global.scss";
.stripe_main_form {
  width: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
  margin: auto;
  .stripe_inner {
    justify-content: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    border-radius: 10px;
    width: 50%;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.08), 0 2px 4px 0 rgba(0, 0, 0, 0.12);
    background: $text-white;
    padding: 20px;
    @media screen and (max-width: $medium) {
      width: 60%;
    }
    @media screen and (max-width: $small) {
      width: 70%;
    }
    @media screen and (max-width: $mobile) {
      width: 95%;
    }
    .payment {
      border: none;
      padding: 10px;
      background-color: $primary-color;
      width: 120px;
      margin: 10px;
      color: $text-white;
      border-radius: 10px;
      cursor: pointer;
    }
  }
}
.card_text {
  font-size: 16px;
  margin: 10px;
  font-weight: bold;
}
.card-number-container {
  margin-bottom: 15px;
  border: 1px solid #42b6e7;
  padding: 5px;
}
.expiry-cvc-container {
  display: flex;
  justify-content: space-between;
  align-items: center; /* Align items vertically in the center */
  margin-bottom: 15px;
}
.CardField {
  width: 48%;
  border: 1px solid #42b6e7;
  padding: 5px;
  /* Adjust width as needed */
}

@import "./global.scss";

.form_main_div {
  display: flex;
  justify-content: center;
  height: 99vh;
  align-items: center;
  .form_inner_div {
    display: flex;
    align-items: center;
    width: 60%;

    @media screen and (max-width: $large) {
      width: 70%;
    }
    @media screen and (max-width: $medium) {
      width: 80%;
    }
    @media screen and (max-width: $small) {
      width: 90%;
    }

    .form_div {
      width: 100%;
      display: flex;
      border-radius: 20px;
      @media screen and (max-width: $medium) {
        display: flex;
        flex-direction: column;
      }
      .sider_main {
        width: 40%;
        background: $secondary-color;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        justify-content: center;
        display: flex;
        align-items: center;
        flex-direction: column;
        @media screen and (max-width: $medium) {
          width: 100%;
          height: 200px;
        }
        .sider {
          padding: 50px;
          @media screen and (max-width: $medium) {
            padding: 20px;
          }
        }
      }
      .form_left {
        width: 60%;
        display: flex;
        justify-content: center;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        background-color: $text-white;
        @media screen and (max-width: $medium) {
          width: 100%;
          height: 350px;
        }
        .login-form {
          padding: 50px;
          width: 400px;
          @media screen and (max-width: $medium) {
            padding: 20px;
          }
        }
      }
    }
  }

  .title {
    text-align: center;
    @media screen and (max-width: $small) {
      font-size: 1.2rem;
    }
  }
  .btn_div {
    display: flex;
    justify-content: center;
  }
}

@import "./global.scss";
.details_card_div {
  display: flex;
  justify-content: center;
  width: 100%;
  .card_inner {
    display: flex;
    justify-content: center;
    width: 80%;
    flex-direction: column;
    margin-top: 20px;
    align-items: center;
    @media screen and (max-width: $medium) {
      width: 90%;
    }
    .cover_img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .detail_img_div {
      border: 1px solid red;
      display: flex;
    }
    .new_card {
      width: 80%;
      display: flex;
      flex-direction: column;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      margin-top: 10px;
      @media screen and (max-width: $medium) {
        width: 90%;
      }
      @media screen and (max-width: $small) {
        width: 95%;
      }
      .cover {
        height: 200px;
        border-radius: 20px 20px 0px 0px;
      }
      .profile {
        object-fit: cover;
        padding-bottom: 30px;
        background: $text-white;
        .profile_inner {
          display: flex;
          justify-content: space-between;
          margin: 10px;
          @media screen and (max-width: $small) {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            gap: 20px;
          }
        }
        .profile_inner_2 {
          margin-left: 25px;
          color: $text-grey;
          @media screen and (max-width: $small) {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
          }
          .title_Div {
            display: flex;
            align-items: center;
            gap: 5px;
          }
        }
        .services {
          display: flex;
          font-size: 1rem;
          color: $text-grey;
          gap: 10px;
          flex-wrap: wrap;
        }
        .tags {
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;
          margin-top: 10px;
          gap: 0px;
        }
        p {
          margin-top: 10px;
        }
      }
    }
    .card-details {
      width: 80%;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      margin: 20px;
      padding: 10px;
      @media screen and (max-width: $medium) {
        width: 90%;
      }
      @media screen and (max-width: $small) {
        width: 95%;
        padding: 0px;
      }
      .card_first {
        width: 100%;
        display: flex;
        justify-content: space-between;
        @media screen and (max-width: $medium) {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-top: 20px;
        }
      }
      .details_div {
        display: flex;
        gap: 20px;
        border-radius: 10px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        @media screen and (max-width: $medium) {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
        .tags {
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;
          margin-top: 10px;
        }
        .contactDetails {
          display: flex;
          justify-content: space-between;
          width: 100%;
          @media screen and (max-width: $medium) {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
          }
        }
        .contact_ {
          display: flex;
          align-items: center;
          gap: 5px;
          @media screen and (max-width: $small) {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            width: 100%;
          }
        }
        .icons {
          padding: 5px;
          color: $text-white;
          background: #172b4d;
          font-size: 16px;
          border-radius: 50%;
        }
      }
    }
    .Title_details {
      font-size: bold;
    }
  }
}

.client_review {
  display: flex;
  justify-content: space-between;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.08), 0 2px 4px 0 rgba(0, 0, 0, 0.12);
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
  @media screen and (max-width: $small) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    padding: 2px;
  }
  .div_1 {
    display: flex;
    gap: 10px;
    width: 75%;
    @media screen and (max-width: $medium) {
      width: 90%;
    }
    @media screen and (max-width: $small) {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
  }
  .div_2 {
    @media screen and (max-width: $small) {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    .name {
      text-transform: capitalize;
    }
  }
  .title_2 {
    font-size: 16px;
  }
  .div_3 {
    width: 23%;
    @media screen and (max-width: $medium) {
      width: 90%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

@import url("./global.scss");
.Main-SupportScreen {
  width: 100%;
  background-color: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 3%;
  .title_support {
    margin: 10px;
    font-weight: bold;
    font-size: 1.5rem;
    @media screen and (max-width: 600px) {
      font-weight: bold;
      font-size: 1rem;
    }
  }
  .heading {
    margin-top: 3%;
    font-weight: bolder;
    align-items: flex-start;
    justify-content: start;
  }

  .ContactUsText {
    background-color: white;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 10px;
    width: 70%;
    padding: 20px;
    .context-class {
      display: flex;
      flex-direction: column;

      .paragraph {
        margin: 2% 0px;
        font-size: 1rem;
        font-weight: bold;
        cursor: pointer;
        display: flex;
        // justify-content: center;
        // align-items: center;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
          Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
          sans-serif;
      }
      .paragraph:hover {
        color: #f48c00;
      }
    }
  }
}

.Main-Support-Detail {
  width: 100%;
  display: flex;

  .Questions-Answer {
    margin-top: 6% !important;
    width: 50%;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background: white;
    margin: auto 2%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 2%;
    padding: 2%;
    .Question {
      padding: 3%;
      width: 85%;

      .Question-text {
        font-weight: bold;
        font-size: 1.5rem;
        @media screen and (max-width: 600px) {
          font-weight: bold;
          font-size: 1rem;
        }
      }
    }
    .Ans {
      width: 85%;
      font-size: xx-large;
      font-weight: 600;
      padding: 4%;
    }
  }
  .Questions-support {
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .Support-button {
    // height: 22%;
    // width: 10%;
    // margin-right: 15%;
    // .btn-5 {
    //   font-weight: bold;
    //   background: #f48c00;
    //   color: white;
    //   font-weight: 400;
    //   border-radius: 10px;
    // }
  }
}
@media screen and (max-width: 900px) {
  .Main-SupportScreen {
    padding: 3%;
  }

  .ContactUsText {
    width: 90% !important;
  }

  .Main-Support-Detail {
    flex-direction: column;
    align-items: center;

    .Questions-Answer,
    .Questions-support {
      width: 90%;
    }
    .Support-button {
      width: 90%;
      margin: 2% auto;
    }
  }
}
